import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from "./accounts/auth.service";
import {FlightSearchInterface} from "./itinerary-service.service";
import {environment} from "../../environment/environment";


@Injectable({
  providedIn: 'root'
})
export class CustomHttpService {
  public apiUrl = environment.corperate_base_url; // Replace with your API URL
  public ticketUrl = `${this.apiUrl}/tickets/`; // Replace with your API URL
  public companytUrl = `${this.apiUrl}/corperates/corperate/`; // Replace with your API URL
  public userUrl = `${this.apiUrl}/users/`;//Replace with your API URL
  public ticketUrlApprove = `${this.apiUrl}/tickets/`; // Replace with your API URL

  constructor(private http: HttpClient) {

  }

 // Fetch a list of all companies
//  getCompanies(): Observable<any[]> {
//   return this.http.get<any[]>(this.companytUrl).pipe(
//     map(response => response),
//     catchError(error => {
//       console.error('Error fetching companies:', error);
//       return throwError(error);
//     })
//   );
// }

  // Fetch a list of all users
getUsers(params: any = {}): Observable<any[]> {
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(this.userUrl, { params: httpParams }).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching users:', error);
      return throwError(error);
    })
  );
}

   // Fetch a specific user by ID
   getUserById(userId: string): Observable<any> {
    return this.http.get<any>(`${this.userUrl}${userId}`).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error fetching user by ID:', error);
        return throwError(error);
      })
    );
  }

  // Create a new user
  createUser(data: any): Observable<any> {
    return this.http.post<any>(this.userUrl, data).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error creating user:', error);
        return throwError(error);
      })
    );
  }

  // Update an existing user by ID
updateUser(userId: string, data: any): Observable<any> {
  // Construct the endpoint with a trailing slash after the user ID
  const url = `${this.userUrl}${userId}/`;

  // Optionally, add custom headers (e.g., if authentication is needed)
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    // Add authentication header if required:
    // 'Authorization': `Bearer ${authToken}`
  });

  console.log('Updating user at URL:', url); // For debugging
  console.log('Payload:', data); // Log the data being sent

  return this.http.put<any>(url, data, { headers }).pipe(
    map(response => {
      console.log('User updated successfully:', response);
      return response;
    }),
    catchError(error => {
      console.error('Error updating user:', error);
      return throwError(error);
    })
  );
}

//Companies section
getCompanies(params: any = {}): Observable<any[]> {
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(this.companytUrl, { params: httpParams }).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching companies:', error);
      return throwError(error);
    })
  );
}

   // Fetch a specific company by ID

   getCompanyById(companyId: string): Observable<any> {
    return this.http.get<any>(`${this.companytUrl}${companyId}/`).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error fetching company by ID:', error);
        return throwError(error);
      })
    );
  }

  // Create a new company

  createCompany(data: any): Observable<any> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      console.log(`Appending: ${key} = ${data[key]}`); // Debug each field
      formData.append(key, data[key]);
    });
  
    return this.http.post<any>(this.companytUrl, formData).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Error creating company:', error);
        return throwError(() => new Error('Failed to create company. Please try again.'));
      })
    );
  }
  
  // Update an existing company by ID

  updateCompany(companyId: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.companytUrl}${companyId}/`, data).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error updating company:', error);
        return throwError(error);
      })
    );
  }

  //delete company
  deleteCompany(companyId: string): Observable<any> {
    return this.http.delete<any>(`${this.companytUrl}${companyId}/`).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error deleting company:', error);
        return throwError(error);
      })
    );
  }


  post<T>(endpoint: string, data: any): Observable<T> {
    return this.http.post<T>(`${this.apiUrl}/${endpoint}`, data);
  }

  get(endpoint: string, data: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${endpoint}`, data);
  }


  getTrips(data:any = {}): Observable<any[]>{
    let params = new HttpParams();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        params = params.set(key, data[key]);
      }
    }
    return this.http
      .get(this.ticketUrl, { params: params })
      .pipe(map((data: any) => {
        return data
      }));
  }

  getBalance(companyID:any):Observable<any>{
    return this.http.get(`${this.companytUrl}${companyID}`).pipe(map(
      (data:any)=>{
        console.log("Balance Balance")
        return data
      }
    ))
  }

  approve(ticketId:string, data = {}) {
      return this.http
        .post(`${this.ticketUrl}${ticketId}/approve/`, data)
        .pipe(map((data:any) =>{
           return data
        }))
  }

  reject(ticketId:string, data = {}) {
    return this.http
      .post(`${this.ticketUrl}${ticketId}/reject/`, data)
      .pipe(map((data:any) =>{
        return data
      }))
  }


}
