import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {BooknowModule} from "./booknow/booknow.module";

const routes: Routes = [
  { path: 'accounts', loadChildren: () => import('./accounts/sign-in.module').then((m) => m.SignInModule) },
  //{ path: 'account', loadChildren: () => import('./accounts/sign-in.module').then((m) => m.SignInModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule) },
  { path: '', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule) },
  { path: 'booking', loadChildren: () => import('./booking/booking.module').then((m) => m.BookingModule) },
  { path: 'booknow', loadChildren: () => import('./booknow/booknow.module').then((m) => m.BooknowModule) },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule) },
  { path: 'compareframe', loadChildren: () => import('./compareframe/compareframe.module').then((m) => m.CompareframeModule) },
  { path: 'trips', loadChildren: () => import('./trips/trips.module').then((m) => m.TripsModule) },
  { path: 'people', loadChildren: () => import('./people/people.module').then((m) => m.PeopleModule) },
  {path: 'company', loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule) },
  { path: 'invoice', loadChildren: () => import('./invoice/invoice.module').then((m) => m.InvoiceModule) },
  { path: 'travelinfo', loadChildren: () => import('./travelinfo/travelinfo.module').then((m) => m.TravelinfoModule) },

  { path: 'confirmbooking', loadChildren: () => import('./confirmbooking/confirmbooking.module').then((m) => m.ConfirmbookingModule) },
  { path: 'reservedetails', loadChildren: () => import('./reservedetails/reservedetails.module').then((m) => m.ReservedetailsModule) },
  { path: 'booknow', loadChildren: () => import('./booknow/booknow.module').then((m) => m.BooknowModule) },
  { path: 'payments', loadChildren: () => import('./payments/payments.module').then((m) => m.PaymentsModule) },
  { path: 'hotelprice', loadChildren: () => import('./hotelprice/hotelprice.module').then((m) => m.HotelpriceModule) },


  { path: 'cardetails', loadChildren: () => import('./cardetails/cardetails.module').then((m) => m.CardetailsModule) },
  { path: 'travelmanagement', loadChildren: () => import('./travelmanagement/travelmanagement.module').then((m) => m.TravelmanagementModule) },
  { path: 'parcelpayment', loadChildren: () => import('./parcelpayment/parcelpayment.module').then((m) => m.ParcelpaymentModule) },
  { path: 'approvalprocess', loadChildren: () => import('./approvalprocess/approvalprocess.module').then((m) => m.ApprovalprocessModule) },
  { path: 'hotelbooking', loadChildren: () => import('./hotelbooking/hotelbooking.module').then((m) => m.HotelbookingModule) },
  { path: 'email-template', loadChildren: () => import('./email-template/email-template.module').then((m) => m.EmailTemplateModule) },
  { path: 'carrental', loadChildren: () => import('./carrental/carrental.module').then((m) => m.CarrentalModule) },
  { path: 'email-temp2', loadChildren: () => import('./email-temp2/email-temp2.module').then((m) => m.EmailTemp2Module) },
  { path: 'email-temp3', loadChildren: () => import('./email-temp3/email-temp3.module').then((m) => m.EmailTemp3Module) },
  { path: 'email-temp4', loadChildren: () => import('./email-temp4/email-temp4.module').then((m) => m.EmailTemp4Module) },
  { path: 'accounts-header', loadChildren: () => import('./sign-in-header/sign-in-header.module').then((m) => m.SignInHeaderModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
